<template>
  <v-col cols="12" md="12" sm="12" class="alt-comp-wrapper">
    <v-autocomplete-alt
      style="width: 100%"
      auto-select-first
      :rules="[allRules.required]"
      label="Manufacturer"
      id="equipmentManufacturer"
      placeholder="Choose a Manufacturer"
      v-model="selected.manufacturerId"
      @input="manufacturerChanged"
      :items="manufacturers"
      :filter="manufacturersFilter"
      dense
      filled
      clearable
      item-text="name"
      item-value="id"
      :loading="isManufacturersLoading"
      :disabled="isManufacturersLoading"
    >
      <template v-slot:selection="{ item }">
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.logoUrl != null && item.logoUrl != ''"
                  >
                    <img
                      :key="item.id + '_img'"
                      :src="item.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.logoUrl != null && item.logoUrl != ''"
                :src="item.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0 ml-2">{{ item.name }}</h5>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.logoUrl != null && item.logoUrl != ''"
                  >
                    <img
                      :key="item.id + '_img'"
                      :src="item.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.logoUrl != null && item.logoUrl != ''"
                :src="item.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0 ml-2">{{ item.name }}</h5>
        </div>
      </template>
    </v-autocomplete-alt>
  </v-col>
</template>

<script>
import manufacturersAPI from "../../Companies/Manufacturers/services/manufacturers-service.js";

export default {
  name: "manufacturer",
  data() {
    return {
      isManufacturersLoading: false,
      manufacturers: [],
      selected: {
        manufacturerId: null,
      },
    };
  },

  methods: {
    getManufacturers() {
      this.isManufacturersLoading = true;
      manufacturersAPI
        .query({ itemsPerPage: -1 })
        .then((resp) => {
          this.manufacturers = resp.data.items;
          this.manufacturerChanged();
          this.isManufacturersLoading = false;
        })
        .catch((err) => {
          this.isManufacturersLoading = false;
          this.$handleError(err);
        });
    },
    manufacturersFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.abbreviatedName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text1.indexOf(searchText) > -1 || text2.indexOf(searchText) > -1;
    },
    manufacturerChanged() {
      var manufacturer = null;
      if (this.selected.manufacturerId != null && this.manufacturers.length > 0)
        manufacturer = this.manufacturers.find((b) => b.id == this.selected.manufacturerId);
      this.$emit("manufacturerChanged", manufacturer);
    },
    onResponse(resp) {
      this.$log("report generate resp.data", resp.data);
      this.$notify(resp.data, "info", resp.data.id, true);
      eventBus.$emit("add-to-download-center", resp.data);
      this.discardGenerate();
    },
    onCatch(err) {
      this.$log(">>> projectService export", err);
      this.isLoading = false;
      this.$dialog.notify.error(err, {
        position: "top-right",
        timeout: 3000,
      });
    },
  },
  mounted() {
    this.getManufacturers();
  },
  computed: {},
  watch: {},
  components: {},
};
</script>

<style lang="scss"></style>
